@media screen and (max-device-aspect-ratio: 9/18){
body {
    height: 100vh;
}
}

.form-login-mobile input:focus{outline:none}

@media (orientation: landscape){
    .form-login-mobile dd .CaptchaPopup-mobile {
        width: 7.66667vw;
        height: 5.06667vw;
        right: 6px;
        border-radius: 1.06667vw;
        top: 1vw;
    }
    }
    .form-login-mobile input {
        position: relative;
        background: #fff;
        border: 1px solid #aaa;
        box-shadow: inset 0 0.53333vw 0 0 rgba(0, 0, 0, 0.1);
        border-radius: 1.6vw;
        color: #1e1e1e;
        font-size: 4vw;
        font-family: Helvetica, Tahoma, sans-serif;
        padding: 2.66667vw 1.86667vw;
        margin-bottom: 1.86667vw;
        -webkit-appearance: none;
        box-sizing: inherit;
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .form-login-mobile   input {
            border-radius: 0.89955vmax;
            font-size: 2.24888vmax;
            padding: 1.49925vmax 1.04948vmax;
            margin-bottom: 1.04948vmax;
        }
    }
        
    .btn-send-mobile,
    .form-login-mobile dt,
    .support-wrap-mobile
     {
        text-align: center;
    }
    
    .btn-send-mobile,
    .login-head-mobile,
    .pass-head,
    .login-head-mobile .close:before,
    .pass-head .close:before,
    .form-login-mobile,
    .form-login-mobile .error:after
     {
        display: block;
    }
    
    .login-head-mobile .close,
    .pass-head .close
     {
        display: flex;
    }
    
    footer .btn-send-mobile {
        box-sizing: border-box;
    }
    
    .btn-send-mobile
     {
        background-image: linear-gradient(-180deg, #fff 0%, #eee 89%);
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        font-size: 4vw;
        font-weight: bold;
        line-height: 2.6;
        color: #983838;
    }
    
    .disable.btn-send-mobile{
        background: #dfdfdf;
        color: #999;
        cursor: not-allowed;
    }
    
    .select.btn-send-mobile{
        background: #ffcc2e;
        border: 1px solid #be7809;
        box-shadow: inset 0 0.53333vw 0 0 rgba(0, 0, 0, 0.1);
    }
    
    .secondary.btn-send-mobile{
        background-image: linear-gradient(180deg, #d6d6d6 0%, #b2b2b2 89%);
        border: 1px solid #969696;
    }
    
    .secondary.btn-send-mobile:hover,
    .pages a.secondary:hover {
        background-image: linear-gradient(180deg, #b2b2b2 0%, #d6d6d6 89%);
        border: 1px solid #969696;
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
       
        .btn-send-mobile
         {
            border-radius: 0.89955vmax;
            font-size: 2.24888vmax;
        }
        .btn.select,
        .select.btn-send-mobile
       {
            box-shadow: inset 0 0.29985vmax 0 0 rgba(0, 0, 0, 0.1);
        }
    }
    
    
    .btn-send-mobile,
    .login {
        background-image: linear-gradient(-180deg, #E9E9E9 15%, #E9E9E9 100%);
        border-color:#101010;
    }
    .btn-send-mobile.disable,
    .disable.login {
        background-image: linear-gradient(-180deg, #ffe9b6 0%, #ffe2b6 100%);
        border-color: #efd9b5;
        color: #999;
    }
    
    .login-head-mobile h1,
    .pass-head h1,
    .login-head-mobile .close,
    .pass-head .close {
        font-size: 0;
        text-indent: -99999px;
    }
    
    
    .form-login-mobile input {
        border-width: 0;
        width: calc(100% - 2 * (1.86vw));
        margin-bottom: 0;
    }
    @media (orientation: landscape) {
        .form-login-mobile input {
            width: calc(100% - 2 * (1.86vw));
            border-radius: 1.6vw;
            font-size: 4vw;
            padding: 2.66667vw 1.86667vw;
        }
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .form-login-mobile input {
            width: calc(100% - 2 * (1.04vmax));
            border-radius: 0.89955vmax;
            font-size: 2.24888vmax;
            padding: 1.49925vmax 1.04948vmax;
        }
    }
    .betbuzz365 .login-head-mobile h1,
    .betbuzz365 .pass-head h1 {
        top: 26.66667vw;
        width: 80.86667vw;
        height: 54.13333vw;
        margin-left: -39.06667vw;
        background-repeat: no-repeat;
    }
    @media (orientation: landscape) {
        .betbuzz365 .login-head-mobile,
        .betbuzz365 .pass-head {
            background-image: linear-gradient(-180deg, #414141 0%, #000 100%);
        }
        .betbuzz365 .login-head-mobile h1,
        .betbuzz365 .pass-head h1 {
            top: 2.13333vw;
            width: 34.46667vw;
            height: 41.33333vw;
          
            margin-left: -15.6vw;
        }
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .betbuzz365 .login-head-mobile h1,
        .betbuzz365 .pass-head h1 {
            top: 1.04948vmax;
            width: 32.93553vmax;
            height: 10.74513vmax;
            margin-left: -12.3928vmax;
        }
    }
    
    .form-login-mobile input.error,
    .error input {
        color: #d0021b;
        background: #fbd7d3;
        border: 1px solid #d0021b;
    }
     .form-login-mobile input:focus {
        color: #1e1e1e;
        background-color: #fff0ca;
        box-shadow: inset 0 0.26667vw 1.33333vw rgba(161, 128, 45, 0.6);
        border-color: #be7809;
    }
    .form-login-mobile input.disable {
        background-color: #dcdcdc;
        color: #666;
        box-shadow: none;
        border: 1px solid #bbb;
    }
    
    
    .login-pop .login-head-mobile,
    .login-pop .pass-head {
        border-radius: 1.6vw 1.6vw 0 0;
    }
    .login-pop .form-login-mobile {
        margin-bottom: 13.33333vw;
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .login-pop .close {
            position: absolute;
            right: 1.04948vmax;
            width: 4.49775vmax;
            height: 4.49775vmax;
            margin-top: 1.04948vmax;
        }
        .login-pop .close:before {
            width: 1.49925vmax;
            height: 1.49925vmax;
        }
        .login-pop .login-head-mobile,
        .login-pop .pass-head {
            border-radius: 0.89955vmax 0.89955vmax 0 0;
        }
        .login-pop .form-login-mobile {
            margin-bottom: 7.49625vmax;
        }
    }
    
    .stake-setting .btn,
    .stake-setting .btn-send-mobile,
    .stake-setting .login,
    .stake-setting .form-select,
    .stake-setting .pages a,
    .pages .stake-setting a {
        flex: 1;
        line-height: 2.2;
    }
    
    .col-stake_edit .btn-send-mobile,
    .col-stake_edit .login {
        line-height: 2.6;
    }
    
    
    
    .login-head-mobile,
    .pass-head {
        position: relative;
        height: 74.66667vw;
        background-size: 100% 100%;
        margin-bottom: 12vw;
    }
    .login-head-mobile h1,
    .pass-head h1 {
        position: absolute;
        left: 61%;
        top: 34.86667vw;
        width: 49.33333vw;
        height: 20.46667vw;
        background-size: cover;
        margin-left: -32.53333vw;
        /* background-size: 200px; */
    }
    .login-head-mobile .betfair-wrap,
    .pass-head .betfair-wrap {
        position: absolute;
        left: 50%;
        bottom: 4vw;
        width: 26.66667vw;
        margin-left: -13.33333vw;
        opacity: 0.5;
        color: #000;
    }
    .login-head-mobile .betfair-wrap p,
    .pass-head .betfair-wrap p {
        text-align: center;
        font-size: 2.66667vw;
    }
    .login-head-mobile .betfair-wrap span,
    .pass-head .betfair-wrap span {
        display: block;
        width: 100%;
        height: 4.8vw;
        /* background-image: url(/images/mobile/login/betfair_black.png); */
        background-repeat: no-repeat;
        background-size: cover;
    }
    .login-head-mobile h3,
    .pass-head h3 {
        position: absolute;
        left: 50%;
        bottom: 5.33333vw;
    }
    .login-head-mobile .close,
    .pass-head .close {
        position: fixed;
        right: 1.86667vw;
        justify-content: center;
        align-items: center;
        width: 9.33333vw;
        height: 9.33333vw;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
        margin-top: 1.86667vw;
        z-index: 2;
    }
    .login-head-mobile .close:before,
    .pass-head .close:before {
        content: "";
        width: 3.46667vw;
        height: 3.46667vw;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9"><path fill="rgb(255,255,255)" fill-rule="evenodd" d="M9 .912L5.412 4.5 9 8.088 8.088 9 4.5 5.412.912 9 0 8.088 3.588 4.5 0 .912.912 0 4.5 3.588 8.088 0z"/></svg>')
            no-repeat;
        background-size: contain;
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .login-head-mobile .close,
        .pass-head .close {
            right: 1.04948vmax;
            width: 5.38vmax;
            height: 5.38vmax;
            margin-top: 1.04948vmax;
        }
        .login-head-mobile .close:before,
        .pass-head .close:before {
            width: 1.94903vmax;
            height: 1.94903vmax;
        }
    }
    @media (orientation: landscape) {
        .login-head-mobile,
        .pass-head {
            height: 21.33333vw;
            background-color: #020202;
            background-image: linear-gradient(-180deg, #020202 0%, #1e1e1de8 58%);
            margin-bottom: 5.33333vw;
        }
        .login-head-mobile h1,
        .pass-head h1 {
            top: 3.73333vw;
            width: 32.2vw;
            height: 14.13333vw;
            margin-left: -20.6vw;
        }
        .login-head-mobile .betfair-wrap,
        .pass-head .betfair-wrap {
            display: none;
        }
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .login-head-mobile,
        .pass-head {
            height: 11.994vmax;
            margin-bottom: 2.9985vmax;
        }
        .login-head-mobile h1,
        .pass-head h1 {
            top: 2.69865vmax;
            width: 33.28336vmax;
            height: 7.94603vmax;
            margin-left: -16.64168vmax;
        }
        .login-head-mobile .betfair-wrap,
        .pass-head .betfair-wrap {
            display: none;
        }
    }
    .form-login-mobile {
        width: 84vw;
        margin: 0 auto 10px;
        color: #fff;
    }
    .form-login-mobile dt {
        font-size: 5.86667vw;
        margin-bottom: 8vw;
    }
    .form-login-mobile dd {
        position: relative;
        margin-bottom: 3.2vw;
    }
    .form-login-mobile dd:last-of-type {
        margin-bottom: 0;
    }
    .form-login-mobile img {
        position: absolute;
        background-color: #fff;
        width: 18.66667vw;
        height: 5.06667vw;
        right: 1.86667vw;
        top: 2.13333vw;
        padding-top: 1.33333vw;
        border-radius: 1.06667vw;
    }
    .pass-wrap .form-login-mobile {
        margin-bottom: 5.33333vw;
    }
    .form-login-mobile .error:after {
        position: absolute;
        right: 1.86667vw;
        top: 3.2vw;
        content: "";
        width: 4.26667vw;
        height: 4.26667vw;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="rgb(208,2,27)" fill-rule="evenodd" d="M8.788 8.788V3.98H7.212V8.79h1.576zm0 3.23v-1.614H7.212v1.615h1.576zM8 0c1.462 0 2.814.365 4.058 1.096 1.205.692 2.154 1.64 2.846 2.846C15.634 5.186 16 6.538 16 8c0 1.462-.365 2.814-1.096 4.058-.692 1.205-1.64 2.154-2.846 2.846C10.814 15.634 9.462 16 8 16c-1.462 0-2.814-.365-4.058-1.096-1.205-.705-2.154-1.66-2.846-2.866C.366 10.795 0 9.448 0 8c0-1.45.365-2.795 1.096-4.038.705-1.206 1.66-2.16 2.866-2.866C5.205.366 6.552 0 8 0z"/></svg>')
            no-repeat;
        background-size: contain;
    }
    .form-login-mobile .error img {
        margin-right: 6.13333vw;
    }
    @media (orientation: landscape) {
        .form-login-mobile {
            width: 84vw;
        }
        .form-login-mobile dt {
            font-size: 5.86667vw;
            margin-bottom: 8vw;
        }
        .form-login-mobile dd {
            margin-bottom: 3.2vw;
        }
        .form-login-mobile img {
            width: 18.66667vw;
            height: 5.06667vw;
            right: 1.86667vw;
            top: 2.13333vw;
            padding-top: 1.33333vw;
            border-radius: 1.06667vw;
        }
        .form-login-mobile .error:after {
            right: 1.86667vw;
            top: 3.2vw;
            width: 4.26667vw;
            height: 4.26667vw;
        }
        .form-login-mobile .error img {
            margin-right: 6.13333vw;
        }
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .form-login-mobile {
            width: 47.22639vmax;
        }
        .form-login-mobile dt {
            font-size: 3.29835vmax;
            margin-bottom: 4.49775vmax;
        }
        .form-login-mobile dd {
            margin-bottom: 1.7991vmax;
        }
        .form-login-mobile img {
            width: 10.49475vmax;
            height: 2.84858vmax;
            right: 1.04948vmax;
            top: 1.1994vmax;
            padding-top: 0.74963vmax;
            border-radius: 0.5997vmax;
        }
        .form-login-mobile .error:after {
            right: 1.04948vmax;
            top: 1.7991vmax;
            width: 2.3988vmax;
            height: 2.3988vmax;
        }
        .form-login-mobile .error img {
            margin-right: 3.44828vmax;
        }
    }
    
    .state-mobile {
        font-size: 3.2vw;
        color:#fff;
    }
    @media (orientation: landscape) {
        .state-mobile {
            font-size: 3.2vw;
        }
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .state-mobile {
            font-size: 1.7991vmax;
        }
    }
    
    .support-wrap-mobile {
        background-color: rgba(0, 0, 0, 0.6);
    }
    .support-wrap-mobile .support-service-mobile {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 13.33333vw;
        border-top: 0.26667vw solid rgba(255, 255, 255, 0.3);
    }
    .support-wrap-mobile .support-service-mobile::before,
    .support-wrap-mobile .support-service-mobile::after {
        content: "";
        display: block;
        flex: 1;
        align-self: flex-end;
        height: 0.26667vw;
        background-color: rgba(255, 255, 255, 0.3);
        margin-bottom: -0.26667vw;
    }
    .support-wrap-mobile .support-service-mobile a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 13.33333vw;
    }
    .support-wrap-mobile .support-service-mobile a::after {
        content: "";
        position: absolute;
        top: 100%;
        display: block;
        width: 13.33333vw;
        height: 0.26667vw;
        background-color: rgba(255, 255, 255, 0.3);
    }
    .support-wrap-mobile .support-service-mobile a.open::after {
        content: "";
        position: absolute;
        top: 100%;
        height: 7.2vw;
        background-image: url(./images/support_arrow.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-color: initial;
    }
    .support-wrap-mobile .support-service-mobile img {
        width: 6.66667vw;
        height: 100%;
    }
    .support-wrap-mobile .support-info-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3.46667vw 0 2.66667vw;
    }
    .support-wrap-mobile .support-detail-mobile {
        display: none;
    }
    .support-wrap-mobile .support-detail-mobile.open {
        display: block;
    }
    .support-wrap-mobile .support-detail-mobile a {
        display: flex;
        justify-content: center;
        font-size: 3.46667vw;
        align-items: center;
        height: 8vw;
        color: #fff;
        text-decoration: underline;
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .support-wrap-mobile {
            display: none;
        }
    }
    [class^="support-"] img {
        background-size: contain;
        background-repeat: no-repeat;
    }
    .support-fb img,
    .support-ig img {
        width: 5.86667vw !important;
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .support-fb img,
        .support-ig img {
            width: 3.29835vmax !important;
        }
    }
    .support-mail-mobile img {
        background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="rgba(255,255,255,0.6)" fill-rule="nonzero"><path d="M8.292 15.677v3.865a.626.626 0 001.128.37l2.26-3.077-3.388-1.158zM20.738 1.116a.625.625 0 00-.652-.045l-18.75 9.792a.627.627 0 00.087 1.145l5.212 1.782 11.1-9.492-8.59 10.35 8.737 2.985a.628.628 0 00.82-.5l2.291-15.416a.626.626 0 00-.255-.601z"/></g></svg>');
    }
    .support-mail-mobile.open img {
        background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="rgb(255,255,255)" fill-rule="nonzero"><path d="M8.292 15.677v3.865a.626.626 0 001.128.37l2.26-3.077-3.388-1.158zM20.738 1.116a.625.625 0 00-.652-.045l-18.75 9.792a.627.627 0 00.087 1.145l5.212 1.782 11.1-9.492-8.59 10.35 8.737 2.985a.628.628 0 00.82-.5l2.291-15.416a.626.626 0 00-.255-.601z"/></g></svg>');
    }
    .support-whatsapp-mobile img {
        background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11.002 1h-.005C5.485 1 1 5.485 1 11c0 2.188.705 4.215 1.904 5.861l-1.246 3.715L5.5 19.347A9.913 9.913 0 0011.002 21C16.517 21 21 16.514 21 11S16.516 1 11.002 1zm5.82 14.121c-.242.681-1.2 1.246-1.963 1.412-.523.11-1.205.2-3.503-.753-2.938-1.218-4.831-4.204-4.978-4.398-.142-.193-1.188-1.58-1.188-3.016 0-1.435.729-2.134 1.022-2.434.242-.246.64-.358 1.023-.358.124 0 .235.006.335.011.294.012.441.03.635.494.241.581.829 2.016.899 2.163.071.148.142.348.042.542-.094.2-.176.288-.324.458-.147.17-.287.3-.434.483-.136.159-.288.329-.118.622.17.288.758 1. 1.623 2.017 1.116.993 2.02 1.311 2.345 1.446.24.1.528.076.705-.111.223-.242.5-.642.78-1.035.2-.283.453-.318.718-.218.27.094 1.699.8 1.993.946.293.148.487.218.558.342.07.123.07.705-.17 1.387z" fill="rgba(255,255,255,0.6)" fill-rule="nonzero"/></svg>');
    }
    .support-whatsapp-mobile.open img {
        background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11.002 1h-.005C5.485 1 1 5.485 1 11c0 2.188.705 4.215 1.904 5.861l-1.246 3.715L5.5 19.347A9.913 9.913 0 0011.002 21C16.517 21 21 16.514 21 11S16.516 1 11.002 1zm5.82 14.121c-.242.681-1.2 1.246-1.963 1.412-.523.11-1.205.2-3.503-.753-2.938-1.218-4.831-4.204-4.978-4.398-.142-.193-1.188-1.58-1.188-3.016 0-1.435.729-2.134 1.022-2.434.242-.246.64-.358 1.023-.358.124 0 .235.006.335.011.294.012.441.03.635.494.241.581.829 2.016.899 2.163.071.148.142.348.042.542-.094.2-.176.288-.324.458-.147.17-.287.3-.434.483-.136.159-.288.329-.118.622.17.288.758 1. 1.623 2.017 1.116.993 2.02 1.311 2.345 1.446.24.1.528.076.705-.111.223-.242.5-.642.78-1.035.2-.283.453-.318.718-.218.27.094 1.699.8 1.993.946.293.148.487.218.558.342.07.123.07.705-.17 1.387z" fill="rgb(255,255,255)" fill-rule="nonzero"/></svg>');
    }
    .support-telegram-mobile img {
        background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 21c5.524 0 10-4.476 10-10S16.524 1 11 1 1 5.476 1 11s4.476 10 10 10zM5.576 10.783l9.642-3.717c.447-.162.838.109.693.786V7.85l-1.641 7.734c-.122.548-.447.682-.903.423l-2.5-1.842-1.206 1.162c-.133.133-.246.245-.504.245l.177-2.544 4.633-4.186c.202-.177-.045-.277-.31-.1L7.93 12.346l-2.468-.77c-.536-.17-.548-.535.113-.794z" fill="rgba(255,255,255,0.6)" fill-rule="nonzero"/></svg>');
    }
    .support-telegram-mobile.open img {
        background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 21c5.524 0 10-4.476 10-10S16.524 1 11 1 1 5.476 1 11s4.476 10 10 10zM5.576 10.783l9.642-3.717c.447-.162.838.109.693.786V7.85l-1.641 7.734c-.122.548-.447.682-.903.423l-2.5-1.842-1.206 1.162c-.133.133-.246.245-.504.245l.177-2.544 4.633-4.186c.202-.177-.045-.277-.31-.1L7.93 12.346l-2.468-.77c-.536-.17-.548-.535.113-.794z" fill="rgb(255,255,255)" fill-rule="nonzero"/></svg>');
    }
    .support-skype-mobile img {
        background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M20.425 13.123c.145-.652.22-1.327.22-2.02 0-5.193-4.27-9.404-9.539-9.404-.555 0-1.1.047-1.631.137A5.567 5.567 0 006.533 1C3.475 1 1 3.441 1 6.455c0 1.006.278 1.947.76 2.759a9.32 9.32 0 00-.194 1.89c0 5.195 4.272 9.406 9.539 9.406.598 0 1.181-.055 1.747-.158A5.58 5.58 0 0015.47 21C18.524 21 21 18.558 21 15.546c0-.871-.207-1.692-.575-2.423zm-4.426 2.476c-.442.616-1.093 1.104-1.939 1.448-.836.344-1.838.52-2.98.52-1.37 0-2.519-.238-3.416-.708a4.434 4.434 0 01-1.57-1.375c-.407-.575-.61-1.146-.61-1.699 0-.344.133-.643.397-.886.261-.244.596-.365.997-.365.327 0 .61.096.84.287.218.184.407.457.558.805.169.383.353.705.547.957.188.246.457.45.8.612.347.16.813.244 1.386.244.789 0 1.436-.166 1.921-.494.476-.319.706-.704.706-1.174 0-.37-.121-.662-.367-.891-.26-.24-.603-.426-1.018-.555a20.235 20.235 0 00-1.75-.427c-.991-.21-1.832-.457-2.5-.738-.683-.287-1.236-.685-1.638-1.183-.41-.506-.618-1.14-.618-1.885 0-.71.218-1.35.648-1.902.426-.549 1.048-.977 1.852-1.269.791-.288 1.733-.435 2.799-.435.851 0 1.6.098 2.226.29.629.193 1.159.453 1.575.774.42.325.733.669.928 1.029.197.362.298.724.298 1.072 0 .337-.131.643-.391.908a1.32 1.32 0 01-.98.404c-.352 0-.63-.087-.821-.253-.179-.156-.365-.4-.57-.747-.238-.445-.526-.797-.856-1.045-.322-.242-.857-.362-1.594-.362-.683 0-1.24.135-1.653.403-.397.256-.591.551-.591.901 0 .214.063.393.191.546.135.165.327.306.569.426.25.124.509.224.768.294.265.073.708.181 1.32.32.772.163 1.48.347 2.108.545a6.759 6.759 0 011.634.734c.456.294.818.669 1.075 1.119.258.454.388 1.01.388 1.657a3.54 3.54 0 01-.67 2.098z" fill="rgba(255,255,255,0.6)" fill-rule="nonzero"/></svg>');
    }
    .support-skype-mobile.open img {
        background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M20.425 13.123c.145-.652.22-1.327.22-2.02 0-5.193-4.27-9.404-9.539-9.404-.555 0-1.1.047-1.631.137A5.567 5.567 0 006.533 1C3.475 1 1 3.441 1 6.455c0 1.006.278 1.947.76 2.759a9.32 9.32 0 00-.194 1.89c0 5.195 4.272 9.406 9.539 9.406.598 0 1.181-.055 1.747-.158A5.58 5.58 0 0015.47 21C18.524 21 21 18.558 21 15.546c0-.871-.207-1.692-.575-2.423zm-4.426 2.476c-.442.616-1.093 1.104-1.939 1.448-.836.344-1.838.52-2.98.52-1.37 0-2.519-.238-3.416-.708a4.434 4.434 0 01-1.57-1.375c-.407-.575-.61-1.146-.61-1.699 0-.344.133-.643.397-.886.261-.244.596-.365.997-.365.327 0 .61.096.84.287.218.184.407.457.558.805.169.383.353.705.547.957.188.246.457.45.8.612.347.16.813.244 1.386.244.789 0 1.436-.166 1.921-.494.476-.319.706-.704.706-1.174 0-.37-.121-.662-.367-.891-.26-.24-.603-.426-1.018-.555a20.235 20.235 0 00-1.75-.427c-.991-.21-1.832-.457-2.5-.738-.683-.287-1.236-.685-1.638-1.183-.41-.506-.618-1.14-.618-1.885 0-.71.218-1.35.648-1.902.426-.549 1.048-.977 1.852-1.269.791-.288 1.733-.435 2.799-.435.851 0 1.6.098 2.226.29.629.193 1.159.453 1.575.774.42.325.733.669.928 1.029.197.362.298.724.298 1.072 0 .337-.131.643-.391.908a1.32 1.32 0 01-.98.404c-.352 0-.63-.087-.821-.253-.179-.156-.365-.4-.57-.747-.238-.445-.526-.797-.856-1.045-.322-.242-.857-.362-1.594-.362-.683 0-1.24.135-1.653.403-.397.256-.591.551-.591.901 0 .214.063.393.191.546.135.165.327.306.569.426.25.124.509.224.768.294.265.073.708.181 1.32.32.772.163 1.48.347 2.108.545a6.759 6.759 0 011.634.734c.456.294.818.669 1.075 1.119.258.454.388 1.01.388 1.657a3.54 3.54 0 01-.67 2.098z" fill="rgb(255,255,255)" fill-rule="nonzero"/></svg>');
    }
    .support-fb-mobile img {
        background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 0C4.925 0 0 4.946 0 11.046a11.026 11.026 0 009.165 10.88v-8.575H6.512v-3.086h2.653V7.989c0-2.64 1.606-4.079 3.952-4.079 1.124 0 2.09.084 2.37.121v2.76h-1.628c-1.275 0-1.52.61-1.52 1.502v1.97h3.043l-.397 3.086h-2.647V22C17.781 21.335 22 16.688 22 11.043 22 4.946 17.075 0 11 0z" fill="rgba(255,255,255,0.6)" fill-rule="nonzero"/></svg>');
    }
    .support-fb-mobile.open img {
        background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 0C4.925 0 0 4.946 0 11.046a11.026 11.026 0 009.165 10.88v-8.575H6.512v-3.086h2.653V7.989c0-2.64 1.606-4.079 3.952-4.079 1.124 0 2.09.084 2.37.121v2.76h-1.628c-1.275 0-1.52.61-1.52 1.502v1.97h3.043l-.397 3.086h-2.647V22C17.781 21.335 22 16.688 22 11.043 22 4.946 17.075 0 11 0z" fill="rgb(255,255,255)" fill-rule="nonzero"/></svg>');
    }
    .support-ig-mobile img {
        background-image: url('data:image/svg+xml,<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M29.172 0C35.142 0 40 4.857 40 10.828v18.345C40 35.143 35.143 40 29.173 40H10.828C4.858 40 0 35.143 0 29.173V10.828C0 4.858 4.857 0 10.828 0zM20 9.063C13.97 9.063 9.063 13.969 9.063 20c0 6.03 4.906 10.937 10.937 10.937 6.03 0 10.937-4.906 10.937-10.937 0-6.03-4.906-10.937-10.937-10.937zm0 2.345c4.738 0 8.592 3.854 8.592 8.592S24.738 28.592 20 28.592 11.408 24.738 11.408 20s3.854-8.592 8.592-8.592zm11.199-6.23a3.235 3.235 0 00-3.232 3.232 3.235 3.235 0 003.232 3.232A3.235 3.235 0 0034.43 8.41a3.236 3.236 0 00-3.232-3.232zm0 2.345a.888.888 0 010 1.774.888.888 0 010-1.774z" fill="rgba(255,255,255,0.6)" fill-rule="nonzero"/></svg>');
    }
    .support-ig-mobile.open img {
        background-image: url('data:image/svg+xml,<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M29.172 0C35.142 0 40 4.857 40 10.828v18.345C40 35.143 35.143 40 29.173 40H10.828C4.858 40 0 35.143 0 29.173V10.828C0 4.858 4.857 0 10.828 0zM20 9.063C13.97 9.063 9.063 13.969 9.063 20c0 6.03 4.906 10.937 10.937 10.937 6.03 0 10.937-4.906 10.937-10.937 0-6.03-4.906-10.937-10.937-10.937zm0 2.345c4.738 0 8.592 3.854 8.592 8.592S24.738 28.592 20 28.592 11.408 24.738 11.408 20s3.854-8.592 8.592-8.592zm11.199-6.23a3.235 3.235 0 00-3.232 3.232 3.235 3.235 0 003.232 3.232A3.235 3.235 0 0034.43 8.41a3.236 3.236 0 00-3.232-3.232zm0 2.345a.888.888 0 010 1.774.888.888 0 010-1.774z" fill="rgb(255,255,255)" fill-rule="nonzero"/></svg>');
    }
    
    
    footer .btn,
    footer .btn-send-mobile,
    footer .login,
    footer .form-select,
    footer .pages a,
    .pages footer a,
    footer .btn-send-mobile,
    footer .login {
        width: 45.33333vw;
        height: 10.66667vw;
        margin: 0 1.06667vw 0 0;
        font-size: 4vw;
        line-height: 10.66667vw;
    }
    footer .btn-send-mobile,
    footer .login {
        margin: 0 auto;
    }
    @media (orientation: landscape) {
        footer {
            position: fixed;
            padding: 0;
            height: 0;
            border: 0;
        }
        footer .confirm-checkbox {
            display: none;
        }
        footer .btn,
        footer .btn-send-mobile,
        footer .login,
        footer .form-select,
        footer .pages a,
        .pages footer a {
            position: absolute;
            z-index: 10;
            right: 1.86667vw;
            bottom: 22.93333vw;
            width: 10.66667vw;
            height: 10.66667vw;
            font-size: 0;
            margin: 0;
            border-radius: 26.66667vw;
        }
        footer .btn:before,
        footer .btn-send-mobile:before,
        footer .login:before,
        footer .form-select:before,
        footer .pages a:before,
        .pages footer a:before {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 100%;
            border-radius: 26.66667vw;
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="rgb(50,50,50)"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></g></svg>') center
                no-repeat;
            background-size: contain;
            content: "";
        }
        footer .btn-send-mobile,
        footer .login {
            bottom: 6.4vw;
        }
        footer .btn-send-mobile:before,
        footer .login:before {
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="rgb(255,255,255)"><path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path></g></svg>') center
                no-repeat;
            background-size: contain;
        }
        footer .age_box .btn:before,
        footer .age_box .btn-send-mobile:before,
        footer .age_box .login:before,
        footer .age_box .form-select:before,
        footer .age_box .pages a:before,
        .pages footer .age_box a:before {
            background: url('data:image/svg+xml,<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h40v40H0z"/><path fill="rgb(50,50,50)" d="M31.667 10.683l-2.35-2.35L20 17.65l-9.317-9.317-2.35 2.35L17.65 20l-9.317 9.317 2.35 2.35L20 22.35l9.317 9.317 2.35-2.35L22.35 20z"/></g></svg>')
                center no-repeat;
            background-size: contain;
        }
        footer .age_box .btn-send-mobile:before,
        footer .age_box .login:before {
            background: url('data:image/svg+xml,<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h40v40H0z"/><path fill="rgb(255,255,255)" d="M15 26.95L8.05 20l-2.367 2.35L15 31.667l20-20-2.35-2.35z"/></g></svg>')
                center no-repeat;
            background-size: contain;
        }
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        footer .btn,
        footer .btn-send-mobile,
        footer .login,
        footer .form-select,
        footer .pages a,
        .pages footer a {
            right: 1.04948vmax;
            bottom: 12.89355vmax;
            width: 7.1964vmax;
            height: 7.1964vmax;
        }
        footer .btn-send-mobile,
        footer .login {
            bottom: 3.5982vmax;
        }
    
    
        .news-wrap .btn-send-mobile,
        .news-wrap .login {
            position: fixed;
        }
    }
    
    .marquee-pop .function-wrap .btn-send-mobile,
    .marquee-pop .function-wrap .login {
        line-height: 2.2;
        min-width: 26.66667vw;
    }
    
    .app-wrap .btn-send-mobile,
    .app-wrap .login {
        border-width: 0px;
    }
    
      
    @media screen and (min-aspect-ratio: 16 / 9) {
        
        .urgent-notice .btn-send-mobile,
        .urgent-notice .login {
            width: 9.5952vmax;
            height: 9.5952vmax;
            border-left: 0.14993vmax solid rgba(255, 255, 255, 0.6) !important;
        }
    }
    
    
    .betbuzz365 .form-login-mobile input {
        border: 1px solid #666;
    }
    .betbuzz365 .login-head-mobile .betfair-wrap,
    .betbuzz365 .pass-head .betfair-wrap {
        color: #fff;
    }
    
    .betbuzz365 .pass-head {
        background-image: linear-gradient(-180deg, #414141 0%, #000 100%);
    }
    .betbuzz365 .pass-head h1 {
        top: 2.66667vw;
        width: 47.46667vw;
        height: 16vw;
        /* background-image: url(/images/mobile/betbuzz365/logo-skyEX.png); */
        margin-left: -23.73333vw;
    }
    @media (orientation: landscape) {
        .betbuzz365 .pass-head h1 {
            top: 2.66667vw;
            width: 47.46667vw;
            height: 16vw;
            margin-left: -23.73333vw;
        }
    }
    @media screen and (min-aspect-ratio: 16 / 9) {
        .betbuzz365 .pass-head h1 {
            top: 1.49925vmax;
            width: 26.68666vmax;
            height: 8.9955vmax;
            margin-left: -13.34333vmax;
        }
    }
    .betbuzz365 .form-login-mobile dt {
        color: #000;
    }
    .betbuzz365 .btn-send-mobile,
    .betbuzz365 .login {
        background-image: linear-gradient(-180deg, #464646 15%, #121212 100%);
        border-color: #000;
        color: #ffb80c;
        text-decoration: none;
    }
    .betbuzz365 .btn-send-mobile.disable,
    .betbuzz365 .disable.login {
        background-image: linear-gradient(-180deg, #f6ecc6 0%, #e7dcb4 100%);
        border-color: #dbd39f;
        color: #999;
    }
    .betbuzz365 .login:after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path fill="rgb(255,184,12" fill-rule="evenodd" d="M5.672 11.238l2.066-2.355H0V7.117h7.776L5.672 4.762 6.8 3.5 10.82 8 6.8 12.5 5.67 11.238zM13.348 0c.435 0 .82.17 1.153.508.334.338.5.73.5 1.172v11.64c0 .443-.166.834-.5 1.172-.332.34-.717.508-1.152.508H1.898c-.434 0-.818-.17-1.15-.508-.334-.338-.5-.73-.5-1.172V10h1.65v3.32h11.45V1.68H1.898V5H.25V1.68c0-.443.166-.834.5-1.172C1.08.168 1.463 0 1.897 0h11.45z"/></svg>');
    }
    .betbuzz365 .state-mobile {
        color: #222;
    }
    .betbuzz365 .txt-wrap {
        background: rgba(0, 0, 0, 0.8);
        color: rgba(255, 255, 255, 0.6);
    }
    .CaptchaPopup-mobile{
        position: absolute;
        right: 1.86667vw;
        width: 13.6666vw;
        top: 1.69vw;
    }
    
    .logoimgset {
        height: 150px;
    width: 150px;
    margin-top: 9px;
    margin-left: 130px;
    }
    